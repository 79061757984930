import * as React from "react";
import { styled } from "@mui/material/styles";
import { Step, Typography, Stack, StepLabel, Stepper } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CheckIcon from "../assets/icons/CheckIcon";
import FalseCheckIcon from "../assets/icons/FalseCheckIcon";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0098a5",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0098a5",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    backgroundColor: "#0098a5",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#0098a5",
  }),
  ...(ownerState.error && {
    backgroundColor: "", // Red color for error state
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, error } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, error }}
      className={className}
    >
      {error ? <FalseCheckIcon /> : <CheckIcon />}
    </ColorlibStepIconRoot>
  );
}

export default function CustomizedSteppers({ responseData }) {
  const historyData = responseData.historyData || [];

  let activeStep;

  const lastStatusID =
    historyData.length > 0
      ? historyData[historyData.length - 1].StatusID
      : null;
  const isNotDelivered = [6, 7, 8, 9, 10, 16, 17].includes(lastStatusID);

  const steps = [
    { label: "Picked Up", statusIDs: [1, 2, 3, 11, 12, 13, 14, 15] },
    { label: "Processing", statusIDs: [1, 2, 3, 11, 12, 13, 14, 15] },
    { label: "Out for Delivery", statusIDs: [4] },
    {
      label: isNotDelivered ? "Not Delivered" : "Delivered",
      statusIDs: isNotDelivered ? [6, 7, 8, 9, 10, 16, 17] : [5],
    },
  ];

  if (isNotDelivered) {
    activeStep = 3;
  } else {
    const matchingStep = steps.find((step) =>
      step.statusIDs.includes(lastStatusID)
    );
    if (matchingStep) {
      activeStep = steps.indexOf(matchingStep);
    }
  }
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index} completed={index < activeStep}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon
                  {...props}
                  error={step.label === "Not Delivered"}
                />
              )}
            >
              <div>
                <Typography variant="body1">{step.label}</Typography>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
