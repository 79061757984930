import React from "react";

export default function ShipmentIcon() {
  return (
    <div>
      <svg
        version="1.1"
        width="90"
        height="90"
        x="0"
        y="0"
        viewBox="0 0 450 450"
      >
        <g>
          <g fillRule="evenodd" clipRule="evenodd">
            <path
              fill="#ef5350"
              d="M397.8 169c-4.5 7.9-8.3 14.7-10.5 19-.4.8-1.1 1.5-1.9 2-2.2 1.2-4.9.3-6.1-1.9-2.2-4.3-6.1-11.1-10.5-19.1-17-30.3-43.4-77.5-43.4-96.2 0-16 6.5-30.5 17-41s25-17 41-17 30.5 6.5 41 17 17 25 17 41c-.1 18.7-26.6 65.9-43.6 96.2z"
              opacity="1"
              data-original="#ef5350"
            ></path>
            <path
              fill="#c24441"
              d="M383.3 28.7c12.2 0 23.2 4.9 31.1 12.9 8 8 12.9 19 12.9 31.1s-4.9 23.2-12.9 31.1-19 12.9-31.1 12.9-23.2-4.9-31.1-12.9-12.9-19-12.9-31.1 4.9-23.2 12.9-31.1 18.9-12.9 31.1-12.9z"
              opacity="1"
              data-original="#c24441"
            ></path>
            <path
              fill="#f5f5f5"
              d="M407.8 48.2c-6.3-6.3-14.9-10.2-24.5-10.2s-18.3 4-24.5 10.2c-6.3 6.3-10.2 14.9-10.2 24.5s3.9 18.3 10.2 24.5c6.3 6.3 14.9 10.2 24.5 10.2s18.3-3.9 24.5-10.2C414.1 91 418 82.3 418 72.8s-3.9-18.3-10.2-24.6z"
              opacity="1"
              data-original="#f5f5f5"
            ></path>
            <path
              fill="#ef5350"
              d="M199.1 140c-2.6 4.5-4.7 8.4-6 10.9-.2.5-.6.9-1.1 1.1-1.3.7-2.8.2-3.5-1.1-1.3-2.4-3.5-6.3-6-10.9-9.7-17.3-24.8-44.3-24.8-55 0-9.2 3.7-17.4 9.7-23.4s14.3-9.7 23.4-9.7 17.4 3.7 23.4 9.7 9.7 14.3 9.7 23.4c0 10.7-15.1 37.7-24.8 55z"
              opacity="1"
              data-original="#ef5350"
            ></path>
            <path
              fill="#c24441"
              d="M190.8 59.8c6.9 0 13.3 2.8 17.8 7.4S216 78 216 85c0 6.9-2.8 13.3-7.4 17.8-4.6 4.6-10.9 7.4-17.8 7.4s-13.3-2.8-17.8-7.4c-4.6-4.6-7.4-10.8-7.4-17.8 0-6.9 2.8-13.3 7.4-17.8s10.8-7.4 17.8-7.4z"
              opacity="1"
              data-original="#c24441"
            ></path>
            <path
              fill="#f5f5f5"
              d="M204.8 71c-3.6-3.6-8.6-5.8-14-5.8-5.5 0-10.4 2.2-14 5.8s-5.8 8.6-5.8 14c0 5.5 2.2 10.4 5.8 14s8.6 5.8 14 5.8c5.5 0 10.4-2.2 14-5.8s5.8-8.6 5.8-14c0-5.5-2.2-10.4-5.8-14z"
              opacity="1"
              data-original="#f5f5f5"
            ></path>
            <path
              fill="#ef5350"
              d="M59.9 131.4c-3.1 5.5-5.8 10.3-7.3 13.3-.3.6-.8 1.1-1.3 1.4-1.6.8-3.4.2-4.3-1.3-1.6-3-4.2-7.7-7.3-13.3-12-21.2-30.4-54.2-30.4-67.3 0-11.2 4.5-21.3 11.9-28.6s17.5-11.9 28.6-11.9 21.3 4.5 28.6 11.9S90.3 53 90.3 64.2c-.1 13.1-18.5 46.1-30.4 67.2z"
              opacity="1"
              data-original="#ef5350"
            ></path>
            <path
              fill="#c24441"
              d="M49.8 33.4c8.5 0 16.2 3.4 21.8 9s9 13.3 9 21.8-3.4 16.2-9 21.8-13.3 9-21.8 9-16.2-3.4-21.8-9-9-13.3-9-21.8 3.4-16.2 9-21.8 13.3-9 21.8-9z"
              opacity="1"
              data-original="#c24441"
            ></path>
            <path
              fill="#f5f5f5"
              d="M66.9 47.1C62.5 42.7 56.4 40 49.8 40c-6.7 0-12.7 2.7-17.1 7.1s-7.1 10.4-7.1 17.1 2.7 12.7 7.1 17.1 10.4 7.1 17.1 7.1 12.7-2.7 17.1-7.1C71.3 77 74 70.9 74 64.2s-2.7-12.8-7.1-17.1z"
              opacity="1"
              data-original="#f5f5f5"
            ></path>
          </g>
          <path
            fill="#ef5350"
            d="M293 405.1c-2.6 0-4.7-2.1-4.7-4.7V226.3c0-2.6 2.1-4.7 4.7-4.7h105.9c2 0 3.8 1.3 4.4 3.1l35 86.4c.2.6.3 1.2.3 1.8v18.4h-11.2c-5.4 0-9.9 4.4-9.9 9.9 0 5.4 4.4 9.9 9.9 9.9h11.2v49.4c0 2.6-2.1 4.7-4.7 4.7-47-.1-93.9-.1-140.9-.1z"
            opacity="1"
            data-original="#ef5350"
          ></path>
          <path
            fill="#9e3735"
            d="M408.8 405.1H318c-.2-1.5-.2-3.1-.2-4.7 0-12.1 4.8-23.7 13.4-32.3s20.1-13.4 32.3-13.4 23.7 4.8 32.3 13.4 13.4 20.1 13.4 32.3c-.2 1.6-.2 3.2-.4 4.7z"
            opacity="1"
            data-original="#9e3735"
          ></path>
          <path
            fill="#ef5350"
            d="M399.4 405.1h-71.9c-.2-1.5-.3-3.1-.3-4.7 0-10 4.1-19.1 10.6-25.6 6.6-6.6 15.6-10.6 25.6-10.6s19.1 4.1 25.6 10.6c6.6 6.6 10.6 15.6 10.6 25.6.1 1.6 0 3.2-.2 4.7z"
            opacity="1"
            data-original="#ef5350"
          ></path>
          <path
            fill="#9e3735"
            d="M320.8 249.5h59.3c2 0 3.8 1.3 4.4 3.1l25.9 64c1 2.4-.2 5.1-2.6 6.1-.6.2-1.2.3-1.8.3h-85.3c-2.6 0-4.7-2.1-4.7-4.7v-64.2c.1-2.6 2.2-4.6 4.8-4.6zM337.6 332.5c2.6 0 4.7 2.1 4.7 4.7s-2.1 4.7-4.7 4.7h-16.8c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7z"
            opacity="1"
            data-original="#9e3735"
          ></path>
          <path
            fill="#729bc6"
            d="M13.7 176.3c0-2.6 2.1-4.7 4.7-4.7H293c2.6 0 4.7 2.1 4.7 4.7v224c0 2.6-2.1 4.7-4.7 4.7H18.4c-2.6 0-4.7-2.1-4.7-4.7v-224z"
            opacity="1"
            data-original="#729bc6"
          ></path>
          <path
            fill="#4c6682"
            d="M232.3 405.1h-90.8c-.2-1.5-.2-3.1-.2-4.7 0-12.1 4.8-23.7 13.4-32.3s20.1-13.4 32.3-13.4c12.1 0 23.7 4.8 32.3 13.4s13.4 20.1 13.4 32.3c-.2 1.6-.3 3.2-.4 4.7zm-94.5 0H47c-.2-1.5-.2-3.1-.2-4.7 0-12.1 4.8-23.7 13.4-32.3s20.1-13.4 32.3-13.4 23.7 4.8 32.3 13.4 13.4 20.1 13.4 32.3c-.2 1.6-.3 3.2-.4 4.7zM13.7 327.1h283.9v23.2H13.7z"
            opacity="1"
            data-original="#4c6682"
          ></path>
          <g fill="#2e343a">
            <path
              d="M337.8 374.8c6.6-6.6 15.6-10.6 25.6-10.6s19.1 4.1 25.6 10.6c6.6 6.6 10.6 15.6 10.6 25.6s-4.1 19.1-10.6 25.6c-6.6 6.6-15.6 10.6-25.6 10.6s-19.1-4.1-25.6-10.6c-6.6-6.6-10.6-15.6-10.6-25.6 0-9.9 4-19 10.6-25.6zM161.2 374.8c6.6-6.6 15.6-10.6 25.6-10.6s19.1 4.1 25.6 10.6 10.6 15.6 10.6 25.6-4.1 19.1-10.6 25.6c-6.6 6.6-15.6 10.6-25.6 10.6s-19.1-4.1-25.6-10.6-10.6-15.6-10.6-25.6c0-9.9 4.1-19 10.6-25.6zM66.7 374.8c6.6-6.6 15.6-10.6 25.6-10.6s19.1 4.1 25.6 10.6 10.6 15.6 10.6 25.6-4.1 19.1-10.6 25.6c-6.6 6.6-15.6 10.6-25.6 10.6s-19.1-4.1-25.6-10.6-10.6-15.6-10.6-25.6c0-9.9 4.1-19 10.6-25.6z"
              fill="#2e343a"
              opacity="1"
              data-original="#2e343a"
            ></path>
          </g>
          <path
            fill="#a2abb8"
            d="M92.4 377.3c-6.4 0-12.2 2.6-16.3 6.8s-6.8 10-6.8 16.4 2.6 12.2 6.8 16.4 10 6.8 16.3 6.8c6.4 0 12.2-2.6 16.4-6.8s6.8-10 6.8-16.4-2.6-12.2-6.8-16.4-10-6.8-16.4-6.8z"
            opacity="1"
            data-original="#a2abb8"
          ></path>
          <path
            fill="#e6e6e6"
            d="M84.3 392.4c2.1-2.1 4.9-3.4 8.1-3.4s6 1.3 8.1 3.4 3.3 4.9 3.3 8.1-1.3 6-3.3 8.1-4.9 3.4-8.1 3.4-6-1.3-8.1-3.4-3.3-4.9-3.3-8.1 1.2-6 3.3-8.1z"
            opacity="1"
            data-original="#e6e6e6"
          ></path>
          <path
            fill="#bfddff"
            d="M377 258.8h-51.5v54.9h73.6z"
            opacity="1"
            data-original="#bfddff"
          ></path>
          <path
            fill="#ffffff"
            d="M371.2 258.8h-6.8l-31.6 54.9h6.7zM353.4 313.7h6.8l22.9-39.7-2.8-6.9z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#9e3735"
            d="M438.6 322v9.4h-11.2c-5.4 0-9.9 4.4-9.9 9.9 0 5.4 4.4 9.9 9.9 9.9h11.2v9.4h-11.2c-10.6 0-19.2-8.6-19.2-19.2s8.6-19.2 19.2-19.2z"
            opacity="1"
            data-original="#9e3735"
          ></path>
          <path
            fill="#ffe26f"
            d="M438.6 331.3V351h-11.2c-5.4 0-9.9-4.4-9.9-9.9 0-5.4 4.4-9.9 9.9-9.9z"
            opacity="1"
            data-original="#ffe26f"
          ></path>
          <path
            fill="#a2abb8"
            d="M186.9 377.3c-6.4 0-12.2 2.6-16.4 6.8s-6.8 10-6.8 16.4 2.6 12.2 6.8 16.4 10 6.8 16.4 6.8 12.2-2.6 16.4-6.8 6.8-10 6.8-16.4-2.6-12.2-6.8-16.4-10-6.8-16.4-6.8z"
            opacity="1"
            data-original="#a2abb8"
          ></path>
          <path
            fill="#e6e6e6"
            d="M178.8 392.4c2.1-2.1 4.9-3.4 8.1-3.4s6 1.3 8.1 3.4 3.4 4.9 3.4 8.1-1.3 6-3.4 8.1-4.9 3.4-8.1 3.4-6-1.3-8.1-3.4-3.4-4.9-3.4-8.1 1.3-6 3.4-8.1z"
            opacity="1"
            data-original="#e6e6e6"
          ></path>
          <path
            fill="#a2abb8"
            d="M363.4 377.3c-6.4 0-12.2 2.6-16.4 6.8s-6.8 10-6.8 16.4 2.6 12.2 6.8 16.4 10 6.8 16.4 6.8 12.2-2.6 16.4-6.8 6.8-10 6.8-16.4-2.6-12.2-6.8-16.4-10-6.8-16.4-6.8z"
            opacity="1"
            data-original="#a2abb8"
          ></path>
          <path
            fill="#e6e6e6"
            d="M355.4 392.4c2.1-2.1 4.9-3.4 8.1-3.4s6 1.3 8.1 3.4 3.4 4.9 3.4 8.1-1.3 6-3.4 8.1-4.9 3.4-8.1 3.4-6-1.3-8.1-3.4-3.4-4.9-3.4-8.1 1.3-6 3.4-8.1z"
            opacity="1"
            data-original="#e6e6e6"
          ></path>
          <path
            fill="#ffd47d"
            d="M82.2 186.4h146.9c3.9 0 7.5 1.6 10.1 4.2s4.2 6.2 4.2 10.1v97.4c0 3.9-1.6 7.5-4.2 10.1s-6.2 4.2-10.1 4.2H82.2c-3.9 0-7.5-1.6-10.1-4.2s-4.2-6.2-4.2-10.1v-97.4c0-3.9 1.6-7.5 4.2-10.1s6.2-4.2 10.1-4.2z"
            opacity="1"
            data-original="#ffd47d"
          ></path>
          <path
            fill="#edaf54"
            d="M102.2 186.4h32.9v65.4c0 1.6-1.3 3-3 3-1.1 0-2-.5-2.5-1.4l-11-14.1-11.1 14.3c-1 1.3-2.9 1.5-4.2.5-.8-.6-1.1-1.5-1.1-2.3zM176.3 186.4h32.9v65.4c0 1.6-1.3 3-3 3-1.1 0-2-.5-2.5-1.4l-11-14.1-11.1 14.3c-1 1.3-2.9 1.5-4.2.5-.8-.6-1.1-1.5-1.1-2.3z"
            opacity="1"
            data-original="#edaf54"
          ></path>
          <path
            fill="#d78e44"
            d="M90.6 263.5h22c1.6 0 3 1.3 3 3v28.2c0 1.6-1.3 3-3 3h-22c-1.6 0-3-1.3-3-3v-28.2c.1-1.6 1.4-3 3-3zM131.2 263.5h22c1.6 0 3 1.3 3 3v28.2c0 1.6-1.3 3-3 3h-22c-1.6 0-3-1.3-3-3v-28.2c.1-1.6 1.4-3 3-3z"
            opacity="1"
            data-original="#d78e44"
          ></path>
          <path
            fill="#edaf54"
            d="M171.8 269.5c-1.6 0-3-1.3-3-3s1.3-3 3-3h48.9c1.6 0 3 1.3 3 3s-1.3 3-3 3zM171.8 283.6c-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3h48.9c1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3zM171.8 297.7c-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3h48.9c1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3z"
            opacity="1"
            data-original="#edaf54"
          ></path>
          <path
            fill="#ef5350"
            fillRule="evenodd"
            d="M173.8 34.6c1.6 2.1 1.1 5.1-1 6.7s-5.1 1.1-6.7-1c-2.6-3.6-5.6-6.5-8.8-8.9-6.3-4.7-13.6-7-20.8-7s-14.4 2.3-20.8 7c-3.2 2.4-6.2 5.3-8.8 8.9-1.6 2.1-4.6 2.6-6.7 1s-2.6-4.6-1-6.7c3.2-4.3 6.9-8 10.8-10.9 8-6 17.2-8.9 26.4-8.9s18.4 3 26.4 8.9c4.1 2.9 7.8 6.5 11 10.9zM243.9 42.1c-2.6-.5-4.2-3.1-3.7-5.7s3.1-4.2 5.7-3.7c.2 0 56.1 11.4 64.2 81.2.3 2.6-1.6 5-4.2 5.3s-5-1.6-5.3-4.2c-7.3-62.9-56.5-72.9-56.7-72.9z"
            clipRule="evenodd"
            opacity="1"
            data-original="#ef5350"
          ></path>
        </g>
      </svg>
    </div>
  );
}
