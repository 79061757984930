import React from "react";

export default function PencilIcon() {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.69168 2.92505L13.075 6.30838M4.5 14.3L1 15L1.7 11.5L12.2 1L15 3.8L4.5 14.3Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  );
}
