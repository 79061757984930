import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import EditAddress from "./pages/EditAddress";
import ShipmentDetails from "./pages/ShipmentDetails";
import ShipmentDetailsModify from "./pages/ShipmentDetailsModify";
export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to="/shipment-details-modify/:companyid/:trackingid"
              replace
            />
          }
        />
        <Route
          path="/edit-address/:companyid/:trackingid"
          element={<EditAddress />}
        />
        <Route
          path="/shipment-details/:companyid/:trackingid"
          element={<ShipmentDetails />}
        />
        <Route
          path="/shipment-details-modify/:companyid/:trackingid"
          element={<ShipmentDetailsModify />}
        />
      </Routes>
    </>
  );
}
