import React from "react";

export default function CheckIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="15"
        height="19"
        x="0"
        y="10"
        viewBox="0 0 512 400"
      >
        <g>
          <linearGradient
            id="a"
            x1="256.02"
            x2="256.02"
            y1="514.555"
            y2="7.605"
            gradientTransform="matrix(1 0 0 -1 0 513.85)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="1" stopColor="#fdae3e" offset="0"></stop>
            <stop
              stopOpacity="1"
              stopColor="#ffffff"
              offset="0.01620369116683342"
            ></stop>
            <stop
              stopOpacity="1"
              stopColor="#ffffff"
              offset="0.035493813715154685"
            ></stop>
            <stop
              stopOpacity="1"
              stopColor="#ffffff"
              offset="0.07407405881179721"
            ></stop>
            <stop
              stopOpacity="1"
              stopColor="#ffffff"
              offset="0.4290123137009084"
            ></stop>
            <stop stopOpacity="1" stopColor="#ffffff" offset="1"></stop>
          </linearGradient>
          <path
            fill="url(#a)"
            d="M491.7 79.2c-27.1-27-70.9-27-98 0L187.2 285.7l-68.8-68.8c-27.1-27-70.9-27-98 0-27.1 27.1-27.1 70.9 0 98l117.8 117.9c27.1 27.1 70.9 27.1 98 0l255.6-255.5c27-27.1 27-71-.1-98.1z"
            opacity="1"
            data-original="url(#a)"
          ></path>
        </g>
      </svg>
    </div>
  );
}
