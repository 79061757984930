import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Button, Card, TextField, Snackbar, Alert } from "@mui/material";
import styles from "../assets/css/modules/editAddress.module.css";
import { useState } from "react";
import AppLoading from "../components/utilities/AppLoading";

export default function EditAddress() {
  let { companyid, trackingid } = useParams();
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [snackBarMess, setSnackBarMess] = useState();
  const [severity, setSeverity] = useState();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!address) {
      setError("Address is required");
      return;
    }
    setError("");
    try {
      setIsLoading(true);
      const response = await axios.post(
        `https://vsofttracking.com-eg.net/api/${companyid}/Tracking/V6/UpdateAddress`,
        {
          trackingID: trackingid,
          address: address,
        }
      );
      setIsLoading(false);
      setOpen(true);
      setSeverity("success");
      setAddress("");
      setSnackBarMess("Address updated successfully !");
    } catch (error) {
      setIsLoading(false);
      setOpen(true);
      setSeverity("error");
      setSnackBarMess(error.response.data.message);
    }
  };

  return (
    <>
      {isLoading && <AppLoading />}
      <div className={styles.container}>
        <div className={styles.cardContainer}>
          <Card className={styles.logoCard}></Card>
          <Card className={styles.infoCard}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 10px",
              }}
            >
              <p>Company Name</p>
              <p>اسم الشركة</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 10px",
              }}
            >
              <p>Company Info</p>
              <p>معلومات عن الشركة</p>
            </Box>
          </Card>
        </div>
        <div>
          <Card className={styles.cardDescription}>
            <p>من فضلك قم بادخال العنوان المطلوب توصيل الشحنة اليه</p>
            <p>Please enter the delivery address</p>
          </Card>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              className={styles.textField}
              label={"Enter New Address"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              error={!!error}
              helperText={error}
            />
          </div>
          <Box className={styles.buttonContainer}>
            <Button className={styles.saveButton} type="submit">
              Save <span className={styles.gap}></span> حفظ
            </Button>
            <Button
              className={styles.resetButton}
              onClick={() => {
                setAddress("");
                setError("");
              }}
            >
              Reset<span className={styles.gap}></span> اعادة
            </Button>
          </Box>
        </form>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity={severity} variant="filled">
            {snackBarMess}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
