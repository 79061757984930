import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@mui/material/styles/styled";

import { useParams } from "react-router-dom";
import styles from "../assets/css/modules/shipmentDetailsModify.module.css";
import {
  Box,
  Typography,
  Modal,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import ShipmentIcon from "../assets/icons/ShipmentIcon";
import CustomizedSteppers from "../components/CustomizedSteppers";
import PencilIcon from "../assets/icons/PencilIcon";
import shipmentData from "../assets/ShipmentData.json";
import AppLoading from "../components/utilities/AppLoading";

const StyledTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "#0098a5",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#0098a5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0098a5",
    },
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f",
    },
  },
}));

export default function ShipmentDetailsModify() {
  let { companyid, trackingid } = useParams();
  const [responseData, setResponseData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [error, setError] = useState("");
  const [address, setAddress] = useState("");
  const [snackBarMess, setSnackBarMess] = useState();
  const [severity, setSeverity] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("done");
    if (!address) {
      setError("Address is required");
      return;
    }
    setError("");
    try {
      setIsLoading(true);
      const response = await axios.post(
        `https://vsofttracking.com-eg.net/api/${companyid}/Tracking/V6/UpdateAddress`,
        {
          trackingID: trackingid,
          address: address,
        }
      );

      setIsLoading(false);
      setOpenSnackBar(true);
      setSeverity("success");
      setAddress("");
      setSnackBarMess("Address updated successfully !");
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      setSeverity("error");
      setSnackBarMess(error.response.data.message);
    }
  };

  useEffect(() => {
    async function regeisterHandler(data) {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://vsofttracking.com-eg.net/api/${companyid}/tracking/V6/GetShipmentData/${trackingid}`
        );
        setIsLoading(false);
        const data = response.data.data;
        const formattedDate = formatDate(
          response?.data?.data.shipmentInfo.ExpectedDeliveryDate
        );

        const shipmentDataMap = new Map();
        shipmentData.forEach((item) => {
          shipmentDataMap.set(item.StatusID, item.generalDescription);
        });

        const updatedHistoryData = data.historyData.map((item) => ({
          ...item,
          generalDescription: shipmentDataMap.get(item.StatusID) || "",
        }));

        setResponseData({
          ...data,
          shipmentInfo: {
            ...data.shipmentInfo,
            ExpectedDeliveryDate: formattedDate,
          },
          historyData: updatedHistoryData,
        });

        return response.data.Data;
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(true);
      }
    }
    regeisterHandler();
  }, [companyid, trackingid]);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    if (error) {
      setError("");
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const weekday = date.toLocaleString("default", { weekday: "long" });

    if (date.toDateString() === today.toDateString()) {
      return `Today, ${day} ${month}, ${year}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday, ${day} ${month}, ${year}`;
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return `Tomorrow, ${day} ${month}, ${year}`;
    }

    return `${weekday}, ${day} ${month}, ${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const strMinutes = minutes < 10 ? "0" + minutes : minutes;
    const time = `${hours}:${strMinutes} ${ampm}`;
    return time;
  };

  const getLastStatus = () => {
    if (responseData?.historyData?.length > 0) {
      const lastEntry =
        responseData.historyData[responseData.historyData.length - 1];
      return {
        lastStatusDescription: lastEntry.StatusDescription,
        lastActionDate: formatDate(lastEntry.ActionDate),
        lastGeneralDescription: lastEntry.generalDescription,
        lastStatusID: lastEntry.StatusID,
      };
    }
    return {
      statusDescription: "",
      actionDate: "",
      generalDescription: "",
    };
  };

  const {
    lastStatusDescription,
    lastActionDate,
    lastGeneralDescription,
    lastStatusID,
  } = getLastStatus();

  const groupByDate = (historyData) => {
    return historyData.reduce((groups, item) => {
      const date = new Date(item.ActionDate).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});
  };
  console.log(responseData.historyData);
  const groupedHistoryData = groupByDate(responseData?.historyData || []);

  return (
    <>
      {/* {isLoading && <AppLoading />} */}

      <Box className={styles.logoContainer}>
        <ShipmentIcon />
        <h1 style={{ fontSize: "38px" }}>Shipment Details </h1>
        <p>Your Shipment Details will be available in this page</p>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.card}>
          <Box className={styles.headerCard}>
            <Box sx={{ display: "flex", gap: "10px", fontSize: "13px" }}>
              <p>AWB</p>
              <p>{responseData?.shipmentInfo?.AWB}</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <>
                <p
                  style={{
                    color: "#000",
                    fontSize: "23px",
                    fontWeight: "700",
                  }}
                >
                  {lastGeneralDescription}
                </p>
                <p
                  style={{
                    color: "#0098a5",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {lastGeneralDescription === "Arriving By" &&
                    responseData?.shipmentInfo?.ExpectedDeliveryDate}
                </p>
              </>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", fontSize: "15px" }}>
              <p>{lastStatusDescription}</p>
              <p style={{ color: "#0098a5" }}>{lastActionDate}</p>
            </Box>
          </Box>
          <hr style={{ borderColor: "#00000014" }} />
          <Box sx={{ paddingY: "25px" }}>
            <CustomizedSteppers responseData={responseData} />
          </Box>
        </Box>
        {lastStatusID !== 5 && (
          <Box className={styles.card}>
            <Typography sx={{ padding: "10px 15px 0 15px" }}>
              Delivery Info
            </Typography>
            <Box className={styles.deliveryInfo}>
              <Box className={styles.deliveryInfoCardAddress}>
                <Box>
                  <p>ADDRESS</p>
                  <p style={{ color: "#000" }}>
                    {responseData?.shipmentInfo?.ToAddress}
                  </p>
                </Box>
                <Box>
                  <button
                    className={styles.addressButton}
                    onClick={handleOpenModal}
                  >
                    <PencilIcon />
                    Change address
                  </button>
                </Box>
              </Box>
              <Box className={styles.deliveryInfoCardTime}>
                <Box>
                  <p>EXPECTED DELIVERY DATE</p>
                  <p style={{ color: "#000" }}>
                    {responseData?.shipmentInfo?.ExpectedDeliveryDate}
                  </p>
                </Box>
              </Box>
              <Box
                className={styles.deliveryInfoCardCOD}
                sx={{ borderBottom: "unset", borderRight: "unset" }}
              >
                <p>COD</p>
                <p style={{ color: "#000" }}>
                  {responseData?.shipmentInfo?.COD}
                </p>
              </Box>
            </Box>
          </Box>
        )}

        <Box>
          <Typography
            sx={{
              color: "#667085",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            Tracking details
          </Typography>
          <Box className={styles.trackingDetailsContent}>
            {Object.entries(groupedHistoryData).map(
              ([date, steps], index, array) => (
                <div
                  className={`${styles.timeline} ${
                    index === array.length - 1 ? styles.lastTimeline : ""
                  }`}
                >
                  <div key={index}>
                    <Typography
                      sx={{ marginY: "10px" }}
                      className={styles.updateTime}
                    >
                      <Box>{formatDate(date)}</Box>
                    </Typography>
                    {steps
                      .sort(
                        (a, b) =>
                          new Date(a.ActionDate) - new Date(b.ActionDate)
                      )
                      .map((step, stepIndex) => (
                        <div key={stepIndex} className={styles.timelineItem}>
                          <div className={styles.timelineMessage}>
                            <div>{step.StatusDescription}</div>
                            <div style={{ color: "#667085", fontSize: "14px" }}>
                              {formatTime(step.ActionDate)}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )
            )}
          </Box>
        </Box>
      </Box>
      <Box className={styles.footer}></Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <form onSubmit={handleSubmit}>
          <Box className={styles.modal}>
            <StyledTextField
              label={"Enter New Address"}
              value={address}
              onChange={handleAddressChange}
              error={!!error}
              helperText={error}
              className={styles.textField}
            />
            <Box className={styles.buttonContainer}>
              <button className={styles.saveButton} type="submit">
                Save <span className={styles.gap}></span> حفظ
              </button>
              <button
                className={styles.resetButton}
                onClick={() => {
                  setAddress("");
                  setError("");
                }}
              >
                Reset<span className={styles.gap}></span> اعادة
              </button>
            </Box>
          </Box>
        </form>
      </Modal>
      <Box>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert severity={severity} variant="filled">
            {snackBarMess}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
