import React, { useEffect, useState } from "react";
import styles from "../assets/css/modules/shipmentDetail.module.css";
import { useParams } from "react-router-dom";
import AppLoading from "../components/utilities/AppLoading";
import axios from "axios";
import { Box } from "@mui/material";

export default function ShipmentDetails() {
  const [responseData, setResponseData] = useState("");
  const { companyid, trackingid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function regeisterHandler(data) {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://vsofttracking.com-eg.net/api/${companyid}/tracking/V6/GetShipmentData/${trackingid}`
        );
        setIsLoading(false);
        setResponseData(response.data.data);
        console.log(response);
        return response.data.Data;
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
    regeisterHandler();
  }, [companyid, trackingid]);

  return (
    <>
      {isLoading && <AppLoading />}
      <div className={styles.container}>
        {responseData && (
          <div className={styles.dataContainer}>
            <div className={styles.shipmentData}>
              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
              >
                {/* sx={{ width: "59%" }} */}
                <Box sx={{ width: "67%" }}>
                  <div className={styles.card}>
                    <h3 className={styles.cardTitle}>{"Shipment Info"}</h3>{" "}
                    <div className={styles.cardEntries}>
                      <div className={styles.cardEntry}>
                        {"Shipment Number"} :
                        <div className={styles.entryValue}>
                          {responseData?.shipmentInfo.AWB}
                        </div>
                      </div>
                      {responseData?.shipmentInfo.RunnerName && (
                        <div className={styles.cardEntry}>
                          {"Courier"} :
                          <div className={styles.entryValue}>
                            {`${responseData?.shipmentInfo.RunnerMobile} ${
                              "/" + responseData?.shipmentInfo.RunnerName
                            } `}
                          </div>
                        </div>
                      )}
                      <div className={styles.cardEntry}>
                        {"status"} :
                        <div className={styles.entryValue}>
                          {responseData?.shipmentInfo.StatusName}
                        </div>
                      </div>
                      <div className={styles.cardEntry}>
                        {"delivery date"} :
                        <div className={styles.entryValue}>
                          {responseData?.shipmentInfo.PickupDate.slice(0, 10)}
                        </div>
                      </div>
                      <div className={styles.cardEntry}>
                        {"content"} :
                        <div className={styles.entryValue}>
                          {responseData?.shipmentInfo.SpecialInstruction}
                        </div>
                      </div>
                      {responseData?.shipmentInfo.Reason && (
                        <div className={styles.cardEntry}>
                          {"reason"} :
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.Reason}
                          </div>
                        </div>
                      )}
                      {responseData?.shipmentInfo.awb_vs_awb && (
                        <div className={styles.cardEntry}>
                          {"awb vs awb"} :
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.awb_vs_awb}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.card}>
                    <h3 className={styles.cardTitle}>{"Consignee Info"}</h3>
                    <div className={styles.entriesContainer}>
                      <div className={styles.cardEntries}>
                        {responseData?.shipmentInfo.SenderName && (
                          <div className={styles.cardEntry}>
                            {"main sender"} :
                            <div className={styles.entryValue}></div>
                          </div>
                        )}
                        <div className={styles.cardEntry}>
                          {"sender phone"} :
                          <div className={styles.entryValue}></div>
                        </div>
                        <div className={styles.cardEntry}>
                          {"sub sender"} :
                          <div className={styles.entryValue}></div>
                        </div>
                        <div className={styles.cardEntry}>
                          {"COD"} :
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.COD}
                          </div>
                        </div>
                      </div>
                      <div className={styles.seperator}></div>
                      <div className={styles.cardEntries}>
                        <div className={styles.cardEntry}>
                          {"consignee"} :
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.ConsigneeName}
                          </div>
                        </div>
                        <div className={styles.cardEntry}>
                          {"consignee number"} :
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.Phone1}
                          </div>
                        </div>
                        <div className={styles.cardEntry}>
                          {"city"} :
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.ToCity}
                          </div>
                        </div>
                        <div className={styles.cardEntry}>
                          {"address"}
                          <div className={styles.entryValue}>
                            {responseData?.shipmentInfo.Address}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* sx={{ width: "39%" }} */}
                <Box sx={{ width: "30%" }}>
                  <div className={styles.card}>
                    <h3 className={styles.cardTitle}>{"shipment history"}</h3>
                    <div className={styles.trackingContainer}>
                      {responseData?.historyData.map((status) => (
                        <div key={status.ActionDate}>
                          <div className={styles.track}>
                            <div className={styles.statusIntro}>
                              {status.Status !== "General Update" && (
                                <>
                                  <div>{status.Status}</div>
                                  <h5>
                                    {status.ActionDate.slice(
                                      12,
                                      status.ActionDate.length
                                    )}{" "}
                                    {status.ActionDate.slice(0, 10)}
                                  </h5>
                                </>
                              )}
                            </div>
                            {status.Status === "General Update" ? (
                              <>
                                <div className={styles.reason}>
                                  {status.UpdateNotes}
                                </div>
                              </>
                            ) : (
                              <>
                                {status.Reason && (
                                  <div className={styles.reason}>
                                    {status.Reason}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Box>
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
